export var fallbackLng = 'en-US';
export var languages = [fallbackLng, 'nb-NO'];
export var defaultNS = 'translation';
export function getOptions(lng, ns) {
    if (lng === void 0) { lng = fallbackLng; }
    if (ns === void 0) { ns = defaultNS; }
    return {
        supportedLngs: languages,
        fallbackLng: fallbackLng,
        lng: lng,
        fallbackNS: defaultNS,
        defaultNS: defaultNS,
        ns: ns,
    };
}
