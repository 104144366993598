'use client';
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { languages } from '../../i18n/settings';
import { useRouter } from 'next/navigation';
var emojis = {
    'en-US': '🇬🇧',
    'nb-NO': '🇳🇴',
};
var LanguageSelector = function (_a) {
    var className = _a.className;
    var router = useRouter();
    var _b = useTranslation('i18n'), t = _b.t, i18n = _b.i18n;
    var currentLanugage = i18n.resolvedLanguage;
    var handleChange = function (e) {
        e.preventDefault();
        i18n.changeLanguage(e.target.value);
        router.refresh();
    };
    return (_jsx("select", { value: currentLanugage, onChange: handleChange, className: className, children: languages.map(function (lng) { return (_jsxs("option", { value: lng, children: [emojis[lng] ? "".concat(emojis[lng], " ") : '', t(lng)] }, lng)); }) }));
};
export default LanguageSelector;
